import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Skills`}</h1>
    <p>{`Throughout my professional and academic career, I have utilised several programming languages and application frameworks in a variety of contexts. As my current career focus is on mobile applications' development my strongest skills are in:`}</p>
    <ul>
      <li parentName="ul">{`Android`}</li>
      <li parentName="ul">{`React Native (Expo & Ejected)`}</li>
      <li parentName="ul">{`Kotlin`}</li>
      <li parentName="ul">{`Java`}</li>
      <li parentName="ul">{`Typescript`}</li>
      <li parentName="ul">{`SQL (SQLite, PL/SQL & Postgres)`}</li>
    </ul>
    <p>{`Skills in these tools, as well as the following, have been developed largely in an academic context through subjects like Applications Programming, Software Engineering Studio and Real-Time Operating Systems. These courses provided an academic understanding of each tool which was then extended through personal and commercial applications. By extending this knowledge in a more practical context alongside senior developers, I have gained a more real-world skillset in software engineering.`}</p>
    <p>{`Development in mobile applications requires a significant set of tools to facilitate automated testing and simplify common processes. Some of these tools that I use frequently are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><ul>{` `}<li>{`Firebase`}</li>{` `}<li>{`Jest`}</li>{` `}<li>{`React-testing-library`}</li>{` `}<li>{`Enzyme`}</li>{` `}<li>{`Redux`}</li>{` `}<li>{`React Navigation`}</li>{` `}<li>{`JUnit`}</li>{` `}<li>{`Espresso`}</li>{` `}</ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul>{` `}<li>{`Mockito / Mockk`}</li>{` `}<li>{`Kotlin Coroutines`}</li>{` `}<li>{`Glide`}</li>{` `}<li>{`RxJava`}</li>{` `}<li>{`Robolectric`}</li>{` `}<li>{`Retrofit`}</li>{` `}<li>{`Room`}</li>{` `}</ul></td>
        </tr>
      </tbody>
    </table>
    <p>{`While less frequently used, I also have experience in full-stack web development and more context-specific development through university and commercial applications. Some of these technologies include:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><ul>{` `}<li>{`Laravel`}</li>{` `}<li>{`Express`}</li>{` `}<li>{`.NET Core`}</li>{` `}<li>{`Unity`}</li>{` `}<li>{`React (Gatsby, create-react-app `}{`&`}{` next.js)`}</li>{` `}</ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul>{` `}<li>{`JQuery`}</li>{` `}<li>{`PHP`}</li>{` `}<li>{`Javascript (ES6+)`}</li>{` `}<li>{`C#`}</li>{` `}<li>{`HTML`}</li>{` `}<li>{`CSS`}</li>{` `}</ul></td>
        </tr>
      </tbody>
    </table>
    <p>{`While important, experience with these technical tools only makes up a portion of the skills developed through academia and commercial software engineering. My work in this area has led to my development in communication, agile project management and time management.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      